import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import AOS from "aos";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";

import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AuthService } from "src/app/core/service/auth.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { Apollo } from "apollo-angular";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Url } from "../../services/url.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from '../../../environments/environment';
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  dbres: boolean = false;
  environment = environment;
  authForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  hide = true;
  session = false;
  datavalue: any = [];
  tokenval: any;
  message: String;
  code: String;
  session_active: String;
  user_name: String;
  uname: String;
  shopurl: string;
  listurl: string;
  homeurl: string;
  myaccount: string;
  cpw: string;
  adminpri: string;
  mybills: string;
  myapi: string;
  sessionData: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,  
    private authService: AuthService,
    private rurl: Url,
    private cookieService: CookieService
  ) {
    super();
  }



  ngOnInit() {
    AOS.init();
    this.user_name = "";
    this.session_active = "";

    this.shopurl = this.rurl.shopurl;
    this.listurl = this.rurl.listurl;
    this.homeurl = this.rurl.homeurl;
    this.myaccount = "myaccount";
    this.cpw = "changepw";
    this.adminpri = "adminpri";
    this.mybills = "mybills";
    this.myapi = "myapi";

    const orcoses = this.cookieService.get("orcoses");
    if (orcoses) {
      const decodedToken = this.decodeJwt(orcoses);
      console.log(decodedToken);
      if (decodedToken) {
        this.session = true;
        localStorage.setItem("luid", decodedToken.sub);
        localStorage.setItem("fname", decodedToken.username);
        localStorage.setItem("apikey", decodedToken.api_key);
        localStorage.setItem("country", '77');
        this.user_name = localStorage.getItem("fname");
        if (this.user_name == "null") {
          this.user_name = "Welcome";
        }
      } else {
        console.log("Invalid or expired token");
      }
    } else {
      this.cookieService.delete("orcoses");
      localStorage.removeItem("luid");
      localStorage.removeItem("apikey");
      localStorage.removeItem("fname");
      localStorage.removeItem("country");
      window.location.href = environment.rUrl;
    }
  }

  logout() {
    this.cookieService.delete("orcoses");
    localStorage.removeItem("luid");
    localStorage.removeItem("apikey");
    localStorage.removeItem("fname");
    localStorage.removeItem("country");
    window.location.href = environment.rUrl;
  }
  decodeJwt(token: string): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c: string) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  isMenuOpen = false;

  toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
  }
}
