import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class AddCartService {
  userdatalist1: any[] = []; 

  constructor(private apollo: Apollo) {}

  addcart(plans: any[]) { 
    return this.apollo.mutate({
      mutation: gql`
        mutation cart($plans: [PlanInput!]!, $cname: String, $invadd: String, $taxno: String, $country_id: String, $state_id: String) {
          cart(plans: $plans, discount:"50", cname: $cname, invadd: $invadd, taxno: $taxno, country_id: $country_id, state_id: $state_id   ) {
            cartid
          }
        }
      `,
      variables: {
        plans: plans,
        cname: "Scrutiny Soft",
        invadd: "Chennai",
        taxno: "Test1234",
        country_id: "77",
        state_id: ""
      },
    });
  }
}




